@charset 'UTF-8';

/**
*    Original CSS@frameworks
*
*    @since 2020.3.11
*/

@use 'sass:math';

// 関数
@import 'component/functions';

// 変数
@import 'variables/variables';

// Mixin
@import 'component/mixin';

// 初期設定
@import 'component/initialize';

// レイアウト
@import 'component/layout';

// grid
@import 'component/grid';

// flex
@import 'component/flex';

// header
@import 'component/header';

// footer
@import 'component/footer';

// copyright
@import 'component/copyright';

// typography
@import 'component/typography';

// icon
@import 'component/icon';

// button
@import 'component/button';

// burger
@import 'component/burger';

// navigation
@import 'component/navigation';

// breadlist
@import 'component/breadlist';

// label
@import 'component/label';

// table
@import 'component/table';

// form
@import 'lib-form/form-btn';
@import 'lib-form/form-component';
@import 'lib-form/form-gimmick';
@import 'lib-form/form-layout';
@import 'lib-form/form-modal';
@import 'lib-form/form';

// list
@import 'component/list';

// alert
@import 'component/alert';

// block
@import 'component/block';

// accordion
@import 'component/accordion';

// tab
@import 'component/tab';

// pagination
@import 'component/pagination';

// pagetop
@import 'component/pagetop';

// utilities
@import 'component/utilities';

// adjoin
@import 'component/adjoin';

// pages
@import 'component/pages';

// Template03
@import 'template';

// jQuery Plugin
@import 'lib/jquery.mCustomScrollbar';

// fontawesome v5.12.1 使用ファイル 'variables/_var_icon.scss', '_function.scss', '_icon.scss', '_button.scss', '_list.scss'
@import 'fonts/fontawesome/fontawesome';

// 約物半角（〈〉《》「」『』【】〔〕（）［］｛｝）yakuhanjp にすると約物全部（、。！？〈〉《》「」『』【】〔〕・（）：；［］｛｝
@import 'fonts/yakuhanjp/yakuhanjp_s';
