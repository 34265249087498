@charset 'UTF-8';

// variables
//
$common_margin_padding_scale_md: map-get( $base_font_size_array, md ) / map-get( $base_font_size_array, sm );
$common_margin_padding_scale_lg: map-get( $base_font_size_array, lg ) / map-get( $base_font_size_array, sm );

//

// margin & padding
// ----------------
@for $i from 1 through 25 {
  .mt#{$i * 4} {
    margin-top: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-top: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      margin-top: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
  .mb#{$i * 4} {
    margin-bottom: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-bottom: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      margin-bottom: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
  .mr#{$i * 4} {
    margin-right: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-right: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      margin-right: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
  .ml#{$i * 4} {
    margin-left: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-left: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      margin-left: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
  .pt#{$i * 4} {
    padding-top: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-top: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      padding-top: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
  .pb#{$i * 4} {
    padding-bottom: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-bottom: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      padding-bottom: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
  .pr#{$i * 4} {
    padding-right: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-right: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      padding-right: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
  .pl#{$i * 4} {
    padding-left: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-left: #{($i * 4) / 10 * $common_margin_padding_scale_md}rem !important;
    }
    @include media(lg) {
      padding-left: #{($i * 4) / 10 * $common_margin_padding_scale_lg}rem !important;
    }
  }
}

.m0  { margin         : 0 !important; }
.mt0 { margin-top     : 0 !important; }
.mb0 { margin-bottom  : 0 !important; }
.ml0 { margin-left    : 0 !important; }
.mr0 { margin-right   : 0 !important; }
.p0  { padding        : 0 !important; }
.pt0 { padding-top    : 0 !important; }
.pb0 { padding-bottom : 0 !important; }
.pl0 { padding-left   : 0 !important; }
.pr0 { padding-right  : 0 !important; }

// text-align
// ----------
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align-last: justify;
  text-justify: inter-ideograph;
}

// font color
//
@each $name, $color in $color_pattern_array {
  .fc-#{$name} {
    color: $color;
  }
}

// background color
// ----------------
@each $name, $color in $color_pattern_array {
  .bg-#{$name} {
    background-color: $color;
    &.light {
      background-color: lighten( $color, 30% );
    }
  }
}

// font size
// ---------
@each $name, $size in $font_size_array {
  .#{$name} {
    font-size: $size !important;
  }
}

// Line height
// -----------
@for $i from 10 through 20 {
  .lh-#{$i} {
    line-height: #{$i * 0.1} !important;
  }
}

// Width
// -----
@for $i from 1 through 20 {
  .w#{$i * 5} {
    width: #{$i * 5%} !important;
  }
}

// Common
// ------
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.center-block {
  display: block;
  margin: 0 auto;
}
