@charset 'UTF-8';

// confirm modal window
// -----

$modal-open-class: '.confirm-open';

.form-confirm-wrapper {
  background: $gray-100;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity $base-transition, visibility $base-transition;
  width: 100%;
  height: 100vh;
  z-index: 999;

  #{$modal-open-class} & {
    opacity: 1;
    visibility: visible;
  }
}

body#{$modal-open-class} {
  overflow: hidden;
}

.form-confirm-container {
  background: $white_color;
  box-shadow: $base-box-shadow;
  border-radius: 12px;
  margin: 4% auto 0;
  padding: 1em;
  width: 92%;
  @include media(sm){
    padding: 3em;
    max-width: 768px;
  }
}

.modal-window-body-inner {
  padding: 1.5em;
}

.modal-heading {
  display: block;
  margin-bottom: 2em;
  text-align: center;

  span {
    background: $base;
    border-radius: 2em;
    color: $white_color;
    display: inline-block;
    font-weight: 700;
    padding: .5em 1em;
    // 危険
    &.danger {
      background: $red;
    }
  }

}



h2.confirm-heading {
  color: $base;
  font-size: 1.25em;
  margin-bottom: 2em;
  text-align: center;
}

.confirm-form-container {
  display: block;
  @include media(sm){
    display: flex;
  }

  & + & {
    margin-top: 2em;
  }
}

.confirm-form-title {
  border-bottom: 1px solid $gray-300;
  font-size: .875em;
  font-weight: 700;
  margin: 0 0 1em;
  padding: 0 0 4px;
  @include media(sm){
    border-bottom: none;
    flex: 0 0 36%;
    margin: 0 1em 0 0;
    padding: 0;
    text-align: right;
  }
}

.confirm-form-value {
  @include media(sm){
    flex: 1;
  }
}
