@charset 'UTF-8';

// common font size
//
$font_size_array: (
  'exlarge' : 200%,
  'xxlarge' : 150%,
  'xlarge'  : 125%,
  'large'   : 112.5%,
  'medium'  : 87.5%,
  'small'   : 75%,
  'xsmall'  : 62.5%
) !default;



// heading
//
$h1_font_size: 2.25em;
$h2_font_size: 2em;
$h3_font_size: 1.75em;
$h4_font_size: 1.5em;
$h5_font_size: 1.25em;
$h6_font_size: 1em;



// p タグ内の letter-spacing（単位は自由）
//
$p_letter_spacing_array: (
  sm: 0,
  md: 0,
  lg: 0
) !default;



// p タグ内の line-height
//
$p_line_height_array: (
  sm: 1.8,
  md: 1.8,
  lg: 2
) !default;
