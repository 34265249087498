@charset 'UTF-8';

// heading initialize
//
h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}



// heading
//
h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
  &.first {
    border-bottom: solid 3px darken( $background-body, 5% );
    color: $base_color;
    margin-bottom: 1em;
    text-align: center;
    span {
      border-bottom: solid 3px $second_color;
      display: inline-block;
      margin-bottom: -3px;
      padding-bottom: .5em;
    }
  }
  &.second {
    color: $second_color;
    border-bottom: 4px solid $second_color;
    border-radius: 10px 10px 0 0;
    padding: .3em .4em;
    margin-bottom: 1em;
    background-color: lighten($second_color, 30%);
  }
}

h3 {
  font-size: $h3-font-size;
  &.first{
    background: lighten($second_color, 30%);
    border-left: solid 0.5em $second_color;
    padding: .3em .6em;
    margin-bottom: 1em;
    font-size: 1.5em;
  }
  &.second{
    background: lighten($base_color, 65%);
    border-left: solid 0.5em $base_color;
    padding: .3em .6em;
    margin-bottom: 1em;
    font-size: 1.5em;
  }
  &.third {
    position: relative;
    background: $third_color;
    border: solid 2px darken($third_color, 5%);
    border-radius: 10px;
    color: $white_color;
    font-size: 1.3em;
    // text-shadow: 0 0 1px $white_color;
    margin-bottom: 1em;
    padding: .5em;
    z-index: -4;
  }
  &.mb_narrow {
    margin: 0 0 .4em 0;
  }
}

h4 {
  font-size: $h4-font-size;
  &.first {
    border-bottom: 3px dotted $base_color;
    color: darken($base_color, 3%);
    margin-bottom: 1em;
    padding-bottom: .4em;
    font-size: $h5-font-size;
  }
  &.second {
    border-bottom: 3px dotted $second_color;
    color: darken($second_color, 3%);
    margin-bottom: 1em;
    padding-bottom: .4em;
    font-size: $h5-font-size;
  }
  &.third {
    color: $gray-800;
    margin-bottom: .5em;
    font-size: $h5-font-size;
  }
}

h5 {
  font-size: $h5-font-size;
  &.second {
    color: rgba($base_color , .85);
    margin-bottom: .6em;
  }
}

h6 {
  font-size: $h6-font-size;
  &.second {
    border-bottom: 1px solid #cfd8dc;
    border-left: 1px solid #cfd8dc;
    color: #424242;
    margin-bottom: .6em;
    padding-bottom: .1em;
    padding-left: .4em;
  }
}



// paragraph
//
p {
  font-size: inherit;
  letter-spacing: map-get( $p_letter_spacing_array, sm );
  line-height: map-get( $p_line_height_array, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-get( $p_letter_spacing_array, md );
    line-height: map-get( $p_line_height_array, md );
  }
  @include media(lg) {
    letter-spacing: map-get( $p_letter_spacing_array, lg );
    line-height: map-get( $p_line_height_array, lg );
  }
}



// フォントファミリーを日本語に限定する
//
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}



// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
//
.phrase {
  margin-bottom: 0 !important;
}



// イタリック
//
i {
  font-style: italic;
  line-height: inherit;
}



// ボールド
//
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}



// code
//
pre {
  background: $gray-100;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: $gray-700;
  font-family: 'SourceHanCodeJP-Regular', monospace;
  font-size: .8em;
  font-weight: bold;
  vertical-align: initial;
}
