@charset 'UTF-8';

// border の太さ
//
$block_border: 1px;



// border-radius
//
$block_border_radius: 8px;



// block の box-shadow
//
$block_box_shadow: 0 5px 7px 0 rgba( 0,0,0, .12 );



// background-color, color ( block-header )
//
$block_colors_array: (
  'base'   : ( 'bg' : $base_color,   'color' : #fff ),
  'first'  : ( 'bg' : $first_color,  'color' : #fff ),
  'second' : ( 'bg' : $second_color, 'color' : #fff ),
  'third'  : ( 'bg' : $third_color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth_color, 'color' : #fff ),
  'five'   : ( 'bg' : $five_color,   'color' : #fff )
) !default;
