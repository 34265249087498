@charset 'UTF-8';

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &.li-mb {
    li:not(:last-child) {
      margin-bottom: .8em;
    }
    ul, ol {
      margin-top: .8em;
    }
  }

  &.indent {
    margin-left: 1em;
    ul {
      margin-top: .8em;
    }
  }
}



// ul の装飾
//
ul.disc {
  list-style: disc outside;
  padding-left: 1.4em;
  ul {
    list-style: circle outside;
    padding-left: 1em;
  }
}

ul.square {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon_font_family;
      content: '\f0c8';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.circle {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon_font_family;
      content: '\f111';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-row {
  display: flex;
  flex-flow: row wrap;
  li {
    &:not(:last-child){
      margin-right: .5em;
    }
  }
}

// ol
//
ol.num {
  list-style: decimal outside;
  padding-left: 1em;
  ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
}

// dl
//
dl {
  dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  dd {
    line-height: 1.4;
  }
}

//関連リンク・サイトマップ
.list_group_link {

li {
  padding: 0.8em 2em 0.8em 0.8em;
  position: relative;
  }
li:hover {
  background: lighten($second_color, 30%);
  border-radius: 0.5em;
}

a {
  display: block;
  color:$base_color;

  &::after{
    content: '\f054';
    color: $second_color;
    font-family: $icon-font-family;
    font-weight: 900;
    margin-right: .5em;
    position: absolute;
    top: 50%;
    right: .5em;
    transform: translateY( -50% );
    }
  }
}