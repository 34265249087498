@charset 'UTF-8';

// SELECT PLAN
// Template 03



// 全体のラッパー
.base-contents-wrapper {
  position: relative;
  width: 100%;
}



// メインコンテンツ
.main-contents-wrapper {
  backface-visibility: hidden;
  //transform: translateX(0);
  -webkit-overflow-scrolling: touch;
  transition: transform $menu_transition_time;

  @include media(lg){
    margin-left: $side_navigation_width;
  }

  // メニューオープン時にコンテンツを動かす
  #{$burger_open_class} & {
    transform: translateX( $side_navigation_width );
  }
}



// TOP PAGE
// --------

// Slider
.slider-wrapper {
  margin-bottom: 3em;
  width: 100%;
  img {
    width: 100%;
  }
}

// トピックス
.top-topics {
  a {
    img {
      transition: box-shadow .3s;
      border-radius: 7px;
    }
    &:hover img {
      box-shadow: 0 0 9px 2px #ccc;
      border-radius: 7px;
    }
  }
}

// 新着情報
.top-news-list {
  font-size: 1.125em;
  margin-bottom: 2rem;
  li {
    padding: 1rem 0;
    border-bottom: 2px dotted $second-color;
    @include media(lg) {
      padding: 1.6rem 0;
    }
    &:first-child{
      padding-top: 0;
    }
    dl {
      line-height: 1.6;
      dt {
        display: block;
        color: $base-color;
      }
    }
  }
}



// SUB PAGES
// ---------

// head visual
.sub-visual {
  background: url( '../../images/visual1.jpg' ) 0 0 no-repeat;
  background-size: 100% auto;
  flex: none;
  padding-bottom: calc( 100% * 300 / 1920 );
  position: relative;
  @include media(lg) {
    padding-bottom: calc( 100% * 200 / 1920 );
  }
  &::before{
    background-color: darken(rgba( $base-color, .3 ), 40%);
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sub-text {
    color: $white-color;
    font-size: 1.6em;
    line-height: 1.5;
    position: absolute;
    top: 50%;
    left: 0;
    padding-left: 1em;
    transform: translate(0, -50%);
    text-align: left;
    text-shadow: 0 0 8px $body-text-color;
    @include media(lg) {
      font-size: 1.8em;
      padding-left: 3rem;
    }
  }
}
