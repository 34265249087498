@charset 'UTF-8';

.block {
  background: $white-color;
  border: $block_border solid $second-color;
  border-radius: $block_border_radius;
  box-shadow: $block_box_shadow;
  overflow: hidden;

  &.height {
    height: 100%;
  }

  // border color pattern theme color
  @each $name, $value in $block_colors_array {
    &.#{$name} {
      border: $block_border solid map-get( $value, 'bg' );
    }
  }
  // border color pattern gray & common color
  @each $name, $value in $basic_colors_array {
    &.#{$name} {
      border: $block_border solid $value;
    }
  }
}



.block-header {
  background: multi-array( $block_colors_array, 'base', 'bg' );
  color: multi-array( $block_colors_array, 'base', 'color' );
  border-radius: 0.5em;
  font-size: inherit;
  font-weight: bold;
  padding: .3em 1em calc( .3em + #{$block_border} );

  // bacground color pattern theme color
  @each $name, $value in $block_colors_array {
    .#{$name} & {
      background: map-get( $value, 'bg' );
    }
  }
  // bacground color pattern gray & common color
  @each $name, $value in $basic_colors_array {
    .#{$name} & {
      background: $value;
      @if( $name == 'gray-300' or $name == 'gray-200' or $name == 'gray-100' or $name == 'white' ){
        color: $body_text_color;
      }
    }
  }
}



.block-body {
  line-height: map-get( $p_line_height_array, sm );
  padding: 1em;
  @include media(md) {
    line-height: map-get( $p_line_height_array, md );
  }
  @include media(lg) {
    line-height: map-get( $p_line_height_array, lg );
  }
}



.block-footer {
  background: multi-array( $block_colors_array, 'base', 'bg' );
  color: multi-array( $block_colors_array, 'base', 'color' );
  font-size: inherit;
  font-weight: bold;
  padding: calc( .3em + #{$block_border} ) 1em .3em;

  // bacground color pattern theme color
  @each $name, $value in $block_colors_array {
    .#{$name} & {
      background: map-get( $value, 'bg' );
    }
  }
  // bacground color pattern gray & common color
  @each $name, $value in $basic_colors_array {
    .#{$name} & {
      background: $value;
      @if( $name == 'gray-300' or $name == 'gray-200' or $name == 'gray-100' or $name == 'white' ){
        color: $body_text_color;
      }
    }
  }
}
