@charset 'UTF-8';

.form-notes-container {
  font-weight: 500;
  margin-bottom: 2em;
}



.form-container {
  border-top: $base-form-border;
  display: block;
  @include media(md){
    display: flex;
  }
  &._last {
    border-bottom: $base-form-border;
  }
}

// フォームの項目名表示
.form-title {
  background: $form-title-background-color;
  border-left: $base-form-border;
  border-right: $base-form-border;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .6em 1em;
  width: 100%;
  @include media(md){
    border-right: none;
    width: 26rem;
  }

  // 項目名
  .title {
    color: inherit;
    font-size: .875em;
    font-weight: 500;
    @include media(md){
      flex: 0 0 19rem;
    }
  }

  // 必須と任意
  .optional,
  .required {
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    padding: .4em .4em calc( .4em - 1px );
  }
  // 任意
  .optional {
    background: #339966;
    color: $white_color;
  }
  // 必須
  .required {
    background: #b0120a;
    color: $white_color;
  }
}

// フォーム入力表示
.form-value {
  background: $form-value-background-color;
  border-left: $base-form-border;
  border-right: $base-form-border;
  padding: 1em;
  @include media(md){
    border-left: none;
    flex: 1;
  }
}
