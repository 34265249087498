@charset 'UTF-8';

// border アニメーション
.border-animation {
  &::after {
    background: $border-animation-color;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $border-animation-thickness;
    z-index: 10;
    transform-origin: center;
    transform: scaleX(0);
    transition: all .3s ease;
  }
  #{$form-focus-in-class-name} &,
  #{$form-focus-fixed-class-name} & {
    &::after {
      transform: scaleX(1);
    }
  }
  &._textarea {
    &::after {
      bottom: 4px;
      @include media(lg){
        bottom: 6px;
      }
    }
  }
}



// Placeholder
.placeholder-parent {
  position: relative;
}

.placeholder-item {
  color: $placeholder-color;
  font-size: .875em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transform-origin: 0 50%;
  transition: .2s;

  #{$form-focus-in-class-name} &,
  #{$form-focus-fixed-class-name} & {
    color: $placeholder-after-color;
    font-weight: 700;
    top: -8px;
    transform: scale(.8);
  }
}



// カレンダーアイコンの表示
.icon-calendar {
  &:before {
    content: '';
    background-image: url( '/images/icon/calendar.svg' );
    background-size: contain;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 28px;
    transform: translateY(-50%);
  }
}



// カレンダーの value をクリアするアイコン
.icon-delete {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  opacity: .6;
  transition: opacity $base-transition;
  &:before {
    content: '';
    background-image: url( '/images/icon/dismisscircle.svg' );
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  &:hover {
    opacity: .8;
  }
}



// jquery.validate.min.js エラー表示用
.validate-error {
  display: block;
  margin-top: .25em;
  .error {
    background: $form-error-background-color;
    color: $form-error-color;
    display: inline-block;
    font-size: .725em;
    font-weight: 700;
    margin-top: .6em;
    padding: .235em .5em .2em;
    position: relative;
    &:before { // 吹き出しの口
      content: '';
      border: 4px solid transparent;
      border-bottom: 4px solid $form-error-background-color;
      position: absolute;
      bottom: 100%;
      left: 10%;
      width: 0;
      height: 0;
    }
  }
  // label の横（内）にあるタイプ
  &.label-inside {
    display: inline-block;
    font-size: 1.125em;
    margin-bottom: 0;
    margin-left: .5em;
    .error {
      margin-top: 0;
      &:before {
        content: none;
      }
    }
  }
}
