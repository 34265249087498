@charset 'UTF-8';

// header
//
header {
  background: transparent;
  padding-bottom: map-get( $header_padding_bottom_array, sm );
  position: fixed;
  width: 100%;
  z-index: map-get( $z_index_array, header );
  @include media(md) {
    padding-bottom: map-get( $header_padding_bottom_array, md );
  }
  @include media(lg) {
    padding-bottom: map-get( $header_padding_bottom_array, lg );
    position: static;
  }
}



// header-wrapper
//
.header-wrapper {
  background: $background_header;
  height: map-get( $header_height_array, sm );
  position: relative;
  padding: 0 map-get( $header_container_padding_array, sm );
  @include media(md) {
    height: map-get( $header_height_array, md );
    padding: 0 map-get( $header_container_padding_array, md );
  }
  @include media(lg) {
    height: map-get( $header_height_array, lg );
    padding: 0 map-get( $header_container_padding_array, lg );
  }
  @include width_wrapper( $header_container_min_width_array, $header_container_padding_array );
}



// header-title
//
.header-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
  @include media(lg) {
    left: auto;
    right: 1em;
    transform: translate( 0, -50% );
  }
  img {
    width: 166px;
    height: auto;
    @include media(md) {
      width: 166px;
    }
    @include media(lg) {
      width: 244px;
    }
  }
}



.phone-trigger {
  border-left: solid 1px $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: map-get( $header_height_array, sm );
  height: map-get( $header_height_array, sm );
  padding: 1rem;

  span {
    color: $white-color;
    display: block;
    font-size: 2em;
  }

  @include media(md) {
    width: map-get( $header_height_array, md );
    height: map-get( $header_height_array, md );
  }
  @include media(lg) {
    display: none;
  }
}
