@charset 'UTF-8';

.form-btn {
  background: $base;
  border: $form-btn-border solid transparent;
  border-radius: $form-btn-border-radius;
  box-shadow: 0px 3px 1px -2px rgba( 0, 0, 0, .2 ),
              0px 2px 2px 0px rgba( 0, 0, 0, .14 ),
              0px 1px 5px 0px rgba( 0, 0, 0, .12 );
  cursor: pointer;
  color: $white_color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $form-btn-font-size;
  font-weight: 700;
  line-height: $form-btn-height;
  height: calc( $form-btn-height + $form-btn-border * 2 );
  outline: none;
  padding: 0 $form-btn-padding * 1.25;
  text-align: center;
  text-decoration: none;
  transition: background $base-transition, border-color $base-transition;
  white-space: nowrap;
  // Hover
  &:hover {
    background: lighten( $base, 10% );
    color: $white_color;
  }
  // キャンセルとか
  &.outline {
    background: $white_color;
    border: $form-btn-border solid $gray-600;
    color: $gray-600;
    &:hover {
      background: $gray-600;
      color: $white_color;
    }
  }
  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
