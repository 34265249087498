@charset 'UTF-8';

// icon
// Font Awesome
//
$icon_font_family: 'Font Awesome 5 Free';



// 種類を追加したい場合：'使用する名前': Fontawesomeのコード;
// Fontawesome のアイコンが Solid の場合 font-weight: 900; 、Regular の場合 font-weight: 400;
//
$icon_type_array: (
  'default'  : '\f138', // arrows
  'back'     : '\f100',
  'check'    : '\f00c',
  'duplicate': '\f0c5',
  'edit'     : '\f304',
  'eye'      : '\f06e',
  'eye-slash': '\f070',
  'fax'      : '\f1ac',
  'link'     : '\f0c1',
  'mail'     : '\f0e0',
  'new'      : '\f067',
  'pdf'      : '\f1c1',
  'plus'     : '\f055',
  'privacy'  : '\f13e',
  'send'     : '\f1d8',
  'tel'      : '\f098',
  'trash'    : '\f2ed',
  'update'   : '\f2f1',
  'window'   : '\f2d2'
) !default;
