@charset 'UTF-8';

// footer
//
footer {
  background: transparent;
  flex: none;
  padding-top: map-get( $footer_padding_top_array, sm );
  width: 100%;
  z-index: map-get( $z_index_array, footer );
  @include media(md) {
    padding-top: map-get( $footer_padding_top_array, md );
  }
  @include media(lg) {
    padding-top: map-get( $footer_padding_top_array, lg );
  }
}



// footer-wrapper
//
.footer-wrapper {
  background: $background_footer;
  position: relative;
  padding: 0 map-get( $footer_container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $footer_container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $footer_container_padding_array, lg );
  }
  @include width_wrapper( $footer_container_min_width_array, $footer_container_padding_array );
}



// footer-container
//
.footer-container {
  margin: 0 auto;
  @include width_container( $footer_container_width_array );
}



// footer-inner
//
.footer-inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 1em 0;
}



// リンク
//
.footer-link-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  li {
    &:not(:first-child) {
      margin-left: 1em;
    }
    a {
      color: $base-color;
      transition: color $transition;
      &:hover {
        color: lighten( $base-color, 20% );
      }
    }
  }
}
